<template>
  <div class="b-table__header">
    <div class="b-table__filter"></div>
    <div class="b-table__actions">
      <b-button
        class="btn-brand cursor"
        variant="primary"
        @click="createDocument"
      >
        <img
          src="/img/icons/btn-plus.svg"
          class="mr"
          alt=""
        />
        Добавить документ
      </b-button>
      <input
        id="fileUpload"
        type="file"
        hidden
        :v-model="file"
        @input="set_file"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    props: {
      file: {
        default: null
      },
      set_file: {
        type: Function,
        default: () => {}
      },
      choose_files: {
        type: Function,
        default: () => {}
      }
    },

    data: () => ({
      filter_date_range: ''
    }),

    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange',
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    mounted() {
      if (this.dateRange) {
        this.filter_date_range = this.dateRange.date
      }
    },

    methods: {
      ...mapActions({
        setDateRange: 'operprocess/setDateRange'
      }),

      createDocument() {
        localStorage.setItem('newDocHeader', 'true')
        this.$emit('create_document')
      },

      updateDateRange() {}
    }
  }
</script>

<style scoped lang="scss">
  .datepicker-range {
    width: 210px;
  }
  .b-table__header {
    display: flex;
    justify-content: space-between;
  }
</style>
