var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__header"
  }, [_c('div', {
    staticClass: "b-table__filter"
  }), _c('div', {
    staticClass: "b-table__actions"
  }, [_c('b-button', {
    staticClass: "btn-brand cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.createDocument
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить документ ")]), _c('input', {
    attrs: {
      "id": "fileUpload",
      "type": "file",
      "hidden": "",
      "v-model": _vm.file
    },
    on: {
      "input": _vm.set_file
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }